import _isExtendable from "is-extendable";
import _assignSymbols from "assign-symbols";
var exports = {};
var isExtendable = _isExtendable;
var assignSymbols = _assignSymbols;

exports = Object.assign || function (obj
/*, objects*/
) {
  if (obj === null || typeof obj === "undefined") {
    throw new TypeError("Cannot convert undefined or null to object");
  }

  if (!isObject(obj)) {
    obj = {};
  }

  for (var i = 1; i < arguments.length; i++) {
    var val = arguments[i];

    if (isString(val)) {
      val = toObject(val);
    }

    if (isObject(val)) {
      assign(obj, val);
      assignSymbols(obj, val);
    }
  }

  return obj;
};

function assign(a, b) {
  for (var key in b) {
    if (hasOwn(b, key)) {
      a[key] = b[key];
    }
  }
}

function isString(val) {
  return val && typeof val === "string";
}

function toObject(str) {
  var obj = {};

  for (var i in str) {
    obj[i] = str[i];
  }

  return obj;
}

function isObject(val) {
  return val && typeof val === "object" || isExtendable(val);
}
/**
 * Returns true if the given `key` is an own property of `obj`.
 */


function hasOwn(obj, key) {
  return Object.prototype.hasOwnProperty.call(obj, key);
}

function isEnum(obj, key) {
  return Object.prototype.propertyIsEnumerable.call(obj, key);
}

export default exports;